import { faFacebookF, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


const currentYear = new Date().getFullYear();


function Footer ({style}) {
    return (
        <>

            <footer className={`footer ${style ? 'dark-mode' : ""}`}>
                <Container>
                    <Row>
                        <Col lg={12}>
                        <div className="wrapper d-flex justify-content-center align-items-center mt-5 pt-3">
             
               <div className="button">
            <div className="icon">
            <FontAwesomeIcon icon={faFacebookF} />
            </div>
            <Link to="https://www.facebook.com/Eng.Mo7amed.Hemdan" target="_blank">
               <span>Facebook</span>
               </Link>
         </div>
              
       
         
      
        <div className="button">
            <div className="icon">
            <FontAwesomeIcon icon={faYoutube} />
            </div>
          
        <Link to="https://www.youtube.com/@MohamedHemdan" target="_blank">
        <span className>YouTube</span>
        </Link>
         </div>

                      </div>
                      <h1>اللهم إنا نسألك علماَ نافعاَ ورزقاَ طيباَ وعملاَ متقبلاَ</h1>
                      <div className="develop text-center mt-5  mb-5 pb-3">
                        <span>Developed by</span> <Link to="https://www.facebook.com/said.sayed.3386"  target="_blank" alt="">Said</Link> , <Link to="https://www.facebook.com/ahmed.abdelhamidnassef" target="_blank" alt="">Nassef</Link> <span>All Copy Rights Reserved &copy; {currentYear}</span>
                      </div>
                        </Col>
                    </Row>
                         <Row>
                    <div className="fawrylinks d-flex align-items-center justify-content-center">
                            <Link to="/RefundPolicy">RefundPolicy</Link>
                        <Link to="/PrivacyPolicy">PrivacyPolicy</Link>
                        <Link to="/TermsAndConditions">TermsAndConditions</Link>
                            </div>
                    </Row> 
                </Container>
            </footer>
        </>
    )
}


export default Footer;