import { useEffect } from "react";




function TermsAndConditions () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
                <div className="privacy-policy pt-5 mt-5">
                <h1> Terms and conditions </h1>
                        <div className="content-privacy">
    <h1><strong>البنود والشروط</strong></h1>
    <p>مرحبًا بك في منصة الأستاذ محمد حمدان!</p>
    <p>توضح هذه الشروط والأحكام القواعد واللوائح الخاصة باستخدام منصة الأستاذ محمد حمدان التعليمية على موقع <a href="https://mohamedhemdan.com">Mohamed Hemdan</a>.</p>
    <p>بدخولك إلى هذا الموقع، نفترض أنك تقبل هذه الشروط والأحكام. إذا كنت لا توافق على أي من الشروط والأحكام المذكورة في هذه الصفحة، يُرجى عدم الاستمرار في استخدام منصة الأستاذ محمد حمدان.</p>

    <h1><strong>ملفات تعريف الارتباط</strong></h1>
    <p>نحن نستخدم ملفات تعريف الارتباط لتحسين تجربة المستخدم. بدخولك إلى منصة الأستاذ محمد حمدان، فإنك توافق على استخدام ملفات تعريف الارتباط بما يتماشى مع سياسة الخصوصية الخاصة بنا.</p>
    <p>تستخدم معظم المواقع ملفات تعريف الارتباط لتسهيل وظائف الموقع. قد يستخدم بعض الشركاء المعلنين لدينا أيضًا ملفات تعريف الارتباط لتحسين تجربتك.</p>

    <h1><strong>الترخيص</strong></h1>
    <p>ما لم يُذكر خلاف ذلك، فإن منصة الأستاذ محمد حمدان و/أو المرخصون التابعون لها يمتلكون حقوق الملكية الفكرية لجميع المواد على الموقع. جميع الحقوق محفوظة. يمكنك استخدام المحتوى لأغراض شخصية فقط وفقًا للشروط والأحكام التالية:</p>
    
    <ul>
        <li>يحظر عليك إعادة نشر المواد من منصة الأستاذ محمد حمدان.</li>
        <li>يحظر عليك بيع أو تأجير أو ترخيص مواد من المنصة.</li>
        <li>يحظر عليك إعادة إنتاج أو نسخ المحتوى لأغراض تجارية.</li>
        <li>يحظر عليك إعادة توزيع المحتوى دون إذن كتابي مسبق.</li>
    </ul>

    <h1><strong>التعليقات والمحتوى</strong></h1>
    <p>قد يُسمح للمستخدمين بنشر تعليقات وآراء في بعض مناطق الموقع. نود أن نوضح أن التعليقات تعبر عن آراء المستخدمين فقط ولا تعكس بالضرورة آراء منصة الأستاذ محمد حمدان أو وكلائها.</p>
    <p>نحتفظ بالحق في مراقبة جميع التعليقات وإزالة أي تعليقات غير لائقة أو تخالف الشروط والأحكام.</p>

    <h1><strong>إخلاء المسؤولية</strong></h1>
    <p>إلى الحد الذي يسمح به القانون، لن تكون منصة الأستاذ محمد حمدان مسؤولة عن أي أضرار قد تنجم عن استخدام الموقع أو الاعتماد على المحتوى المتاح عليه.</p>
</div>

                </div>
        </>
    )
}
export default TermsAndConditions;

