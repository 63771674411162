
import './App.css';
import './styles/table.css'
import { createContext, Suspense, useLayoutEffect, lazy , useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Route, Router, Routes} from 'react-router-dom';
import AppNavbar from './user/AppNavbar';
import Footer from './user/Footer';
// import Homepage from './user/Homepage';
import Years from './user/CoursesStudent/Courses';
// import Quiz from './user/QuizStudent/Quiz';
import Profile from './user/ProfileStudent/Profile';
// import Profilecourses from './user/ProfileStudent/Profilecourses';
// import VideoPlayer from './user/VideoStudent/VideoPlayer';
// import Profilequiz from './user/ProfileStudent/Profilequiz';
import Registeruser from './user/RegisterUser';
import Loginuser from './user/LoginUser';
// import Pay from './user/CoursesStudent/ContentCourse';
import { useEffect } from 'react';
// import ChargeWallet from './user/ChargeWallet';
// import Courses from './user/CoursesStudent/Courses';
// import MyCourses from './user/CoursesStudent/MyCourses';
import ModelAnswer from './user/QuizStudent/ModelAnswerQuiz';
// import Homework from './user/HomeworkStudent/Homework';
// import ModelAnswerQuiz from './user/QuizStudent/ModelAnswerQuiz';
// import ModelAnswerHomework from './user/HomeworkStudent/ModelAnswerHomework';
import axios from 'axios';
import swal from 'sweetalert';
// import StartQuiz from './user/QuizStudent/StartQuiz';
// import StartHomework from './user/HomeworkStudent/StartHomework';
// import ProfileHomework from './user/ProfileStudent/ProfileHomework';
// import StartPdf from './user/PdfStudent/Pdf';
// import PayNotLogin from './user/CoursesStudent/ContentCourseNotLogin';
// import NumberPhone from './user/TechnicalSupport/NumberPhone';
import ScrollTopButton from './user/ScrollTop';
// import EditStudentBySelf from './user/EditStudentBySelf';
// import TopRatedQuiz from './user/QuizStudent/TopRatedQuiz';
import Videoo from './user/Videoo';
import DisableIdm from './user/DisableIdm';
import ProfileVideos from './user/ProfileStudent/ProfileVideos';
import TermsAndConditions from './user/TermsAndConditions';
import RefundPolicy from './user/RefundPolicy';
import PrivacyPolicy from './user/PrivacyPolicy ';
// import EditUserData from './user/EditUserData';
// import BooksAndCodes from './user/TechnicalSupport/BooksAndCodes';
// import LessonsInCenter from './user/TechnicalSupport/LessonsInCenter';
// import ProfileWallet from './user/ProfileStudent/ProfileWallet';
// import PayCourse from './user/PayCourse';
// import TestVideo from './user/TestVideo';
// import Loginuser from './user/LoginUser';




const Homepage = lazy(() => import('./user/Homepage'));
const Quiz = lazy(() => import('./user/QuizStudent/Quiz'));
// const Profile = lazy(() => import('./user/ProfileStudent/Profile'));
const Profilecourses = lazy(() => import('./user/ProfileStudent/Profilecourses'));
const VideoPlayer = lazy(() => import('./user/VideoStudent/VideoPlayer'));
const VideoHomework = lazy(() => import('./user/VideoStudent/VideoHomework'));
const Profilequiz = lazy(() => import('./user/ProfileStudent/Profilequiz'));
const Pay = lazy(() => import('./user/CoursesStudent/ContentCourse'));
const Courses = lazy(() => import('./user/CoursesStudent/Courses'));
const MyCourses = lazy(() => import('./user/CoursesStudent/MyCourses'));
const Homework = lazy(() => import('./user/HomeworkStudent/Homework'));
const ModelAnswerQuiz = lazy(() => import('./user/QuizStudent/ModelAnswerQuiz'));
const ModelAnswerHomework = lazy(() => import('./user/HomeworkStudent/ModelAnswerHomework'));
const StartQuiz = lazy(() => import('./user/QuizStudent/StartQuiz'));
const StartHomework = lazy(() => import('./user/HomeworkStudent/StartHomework'));
const ProfileHomework = lazy(() => import('./user/ProfileStudent/ProfileHomework'));
const StartPdf = lazy(() => import('./user/PdfStudent/Pdf'));
const PayNotLogin = lazy(() => import('./user/CoursesStudent/ContentCourseNotLogin'));
const NumberPhone = lazy(() => import('./user/TechnicalSupport/NumberPhone'));
const TopRatedQuiz = lazy(() => import('./user/QuizStudent/TopRatedQuiz'));
const BooksAndCodes = lazy(() => import('./user/TechnicalSupport/BooksAndCodes'));
const LessonsInCenter = lazy(() => import('./user/TechnicalSupport/LessonsInCenter'));
const ProfileWallet = lazy(() => import('./user/ProfileStudent/ProfileWallet'));
const PayCourse = lazy(() => import('./user/PayCourse'));
const TestVideo = lazy(() => import('./user/TestVideo'));
const EditUserData = lazy(() => import('./user/EditUserData'));




// export const ThemeContext = createContext(null);
export const BASE_URL = "https://admin.mohamedhemdan.com";
export const Image_URL = "https://admin.mohamedhemdan.com";

export const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      borderBottom: "3px solid #FFA500"
    },
  },
  cells: {
    style: {
      // fontSize: '17px',
      padding: '20px 15px',
    },
  },
}



function App() {

  useLayoutEffect (() =>{
    const loader = document.getElementById("loader");
    loader.classList.add("loaded");

    setTimeout(() =>{
      const loader = document.getElementById('loader');
      if (document.body.contains(loader)) {
        document.body.removeChild(loader);
      }}, 3000)
  } , []);







  const [loggedIn, setLoggedIn] = useState(0);
  const [role , setRole] = useState(2) 
    const [auth , setAuth] = useState("")
    const getToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoggedIn(0);
        clearToken();
      } else {
        setLoggedIn(1);
        try {
          const response = await axios.get(`${BASE_URL}/api/Auth`, {
            headers: {
              'Content-Type': 'application/json',
              "ngrok-skip-browser-warning": "any",
              Authorization: `Bearer ${token}`,
            },
          });
          setRole(response.data.role);
          setAuth(response.data); // Update auth state with response.data
          setLoggedIn(1);
        } catch (error) {
          if (error?.response?.status === 401) {
            swal({
              title: "! انتهت مدة تسجيل الدخول",
              text: "يرجى تسجيل الدخول مرة أخرى",
              icon: "warning",
              button: "حسنا!",
            });
            setLoggedIn(0);
            clearToken();
            setRole('');
          }
        }
      }
    };
    
        useEffect(() => {
          const token = localStorage.getItem('token');
          if (token) {
            getToken();
          } else {
            getToken();
          }
        }, []);


        const authed = localStorage.getItem("token");
        const roled = localStorage.getItem("sha1");
        
      const clearToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('sha1');
        setLoggedIn(0);
      };



  
     
    
      function NotFound() {
        return (
          <div className="not-found">
            <h1><span>404</span> - Page Not Found</h1>
          </div>
        );
      }

      const location = useLocation();

      // Check if the current route is /video/:id
      const isVideoPage = location.pathname.startsWith('/video/')


          
 
      
      const [isDarkMode, setIsDarkMode] = useState(
        localStorage.getItem('isDarkMode') === 'true' ? true : false
      );
    
      // Toggle dark mode
      const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
      };
    
      // Save dark mode state to local storage
      useEffect(() => {
        localStorage.setItem('isDarkMode', isDarkMode);
      }, [isDarkMode]);
    
      // Add "dark-mode" class to the body
      useEffect(() => {
        if (isDarkMode) {
          document.body.classList.add('dark-mode');
        } else {
          document.body.classList.remove('dark-mode');
        }
      }, [isDarkMode]);
    
      const darkModeStyles = {
        backgroundColor: 'black',
        // Add any additional styles for dark mode
      };

     
    
      
  return (
   
<div className="App">
<Suspense fallback={""}>
        <AppNavbar loggedIn={loggedIn} roled={role} auth={auth} setLoggedIn={setLoggedIn} clearToken={clearToken}  isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} style={isDarkMode ? darkModeStyles : null}/>  
           <Routes basename="/">
    <Route path='/profile' element={!authed ? <Navigate to="/loginuser" /> : <Profile style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/registeruser' element={loggedIn ? <Navigate to="/"/> : <Registeruser style={isDarkMode ? darkModeStyles : null}/>}  />
    <Route path='/loginuser' element={loggedIn ? <Navigate to="/" /> : <Loginuser loggedIn={loggedIn} setLoggedIn={setLoggedIn} style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/' element={<Homepage loggedIn={loggedIn} setLoggedIn={setLoggedIn} auth={auth}  style={isDarkMode ? darkModeStyles : null} />} />
    <Route path='/courses/:id' element={loggedIn ? <Navigate to="/" /> : <Courses style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/course-content/:id' element={loggedIn ? <Navigate to="/" /> : <PayNotLogin  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/mycourses' element={!authed ? <Navigate to="/loginuser" /> : <MyCourses  style={isDarkMode ? darkModeStyles : null}/> }/>
    <Route path='/week/:id/subscribe' element={!authed ? <Navigate to="/loginuser" /> : <PayCourse  style={isDarkMode ? darkModeStyles : null}/> }/>
    <Route path='/course' element={!authed ? <Navigate to="/loginuser" /> : <Courses  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/contentcourse/:id' element={!authed ? <Navigate to="/loginuser" /> : <Pay  auth={auth} style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/quiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <Quiz  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/startquiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <StartQuiz  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/modelanswerquiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <ModelAnswerQuiz  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/TopRatedQuiz/:id' element={!authed ? <Navigate to="/loginuser" /> : <TopRatedQuiz style={isDarkMode ? darkModeStyles : null} />} />
    <Route path='/homework/:id' element={!authed ? <Navigate to="/loginuser" /> : <Homework  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/starthomework/:id' element={!authed ? <Navigate to="/loginuser" /> : <StartHomework  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/modelanswerhomework/:id' element={!authed ? <Navigate to="/loginuser" /> : <ModelAnswerHomework  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/video/:id' element={!authed ? <Navigate to="/loginuser" /> : <VideoPlayer auth={auth}  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/videohomework/:id' element={!authed ? <Navigate to="/loginuser" /> : <VideoHomework auth={auth}  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/startpdf/:id' element={!authed ? <Navigate to="/loginuser" /> : <StartPdf  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/profile/courses' element={!authed ? <Navigate to="/loginuser" /> : <Profilecourses  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/profile/quiz' element={!authed ? <Navigate to="/loginuser" /> : <Profilequiz style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/profile/homeworks' element={!authed ? <Navigate to="/loginuser" /> : <ProfileHomework style={isDarkMode ? darkModeStyles : null}/>}/>
    <Route path='/profile/wallet' element={!authed ? <Navigate to="/loginuser" /> : <ProfileWallet style={isDarkMode ? darkModeStyles : null}/>}/>
    <Route path='/profile/videos' element={!authed ? <Navigate to="/loginuser" /> : <ProfileVideos style={isDarkMode ? darkModeStyles : null}/>}/>
    <Route path='/numberphone' element={ <NumberPhone />} />
    <Route path='/editinfo' element={!authed ? <Navigate to="/loginuser" /> : <EditUserData auth={auth} style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/booksandcodes' element={ <BooksAndCodes  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='/centerschedules' element={ <LessonsInCenter  style={isDarkMode ? darkModeStyles : null}/>} />
    <Route path='*' element={<NotFound />} />
    <Route path='/testvideo' element={<TestVideo />}/>
    <Route path='/videoo' element={<Videoo />}/>
    <Route path='disableIDM' element={<DisableIdm />}/>
    <Route path='/TermsAndConditions' element={<TermsAndConditions/>}/>
    <Route path='/PrivacyPolicy' element={<PrivacyPolicy />}/>
    <Route path='/RefundPolicy' element={<RefundPolicy/>}/>
    
</Routes>



{<Footer style={isDarkMode ? darkModeStyles : null}/>}
 
{/* <IconButton onClick={toggleDarkMode} color="inherit">
  {darkMode ? 'dark' : 'light'}
</IconButton> */}

{/* <ThemeProvider theme={theme}> */}
  {/* <CssBaseline />*/}
  {/* darkMode={darkMode}
    toggleDarkMode={toggleDarkMode} */}
  
      {/* </ThemeProvider> */}


  
      </Suspense>

      </div>
      
  );
}

export default App;
